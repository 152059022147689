import React, { useEffect, useContext, useState } from 'react';
import { Link } from "react-router-dom";
import { LanguageContext } from "../Contexts/Languagecontext.js";
import { Helmet } from "react-helmet";
import { FaBars, FaTimes } from 'react-icons/fa';
import '../stylesheets/App.css';
import facebook from '../images/facebook.png';
import main_img from '../images/main-img.jpg';
import main_img_small from '../images/main-img-small.jpg';
import main_img_en from '../images/main-img-en.jpg';
import main_img_small_en from '../images/main-img-small-en.jpg';
import main_img_lv from '../images/main-img-lv.jpg';
import main_img_small_lv from '../images/main-img-small-lv.jpg';
import custom1 from '../images/custom1.jpg';
import custom2 from '../images/custom2.jpg';
import custom3 from '../images/custom3.jpg';
import custom4 from '../images/custom4.jpg';
import remont1 from '../images/obsluzivanie.jpg';
import remont2 from '../images/dvigatel.jpg';
import remont3 from '../images/svarka.jpg';
import remont4 from '../images/tune.jpg';
import remont5 from '../images/diagnostika.jpg';
import remont6 from '../images/tormoz.jpg';
import remont7 from '../images/podveska.jpg';
import project1 from '../images/project1.jpg';
import project2 from '../images/project2.jpg';
import project3 from '../images/project3.jpg';
import project4 from '../images/project4.jpg';
import project5 from '../images/project5.jpg';
import project6 from '../images/project6.jpg';
import contact1 from '../images/contacts1.jpg';
import contact2 from '../images/contacts2.jpg';
import waze from '../images/waze.jpg';

const Translations_local = {
  ru: {
    abt: "О нас",
    text_abt: "Наш опыт ремонта, обслуживания и улучшения BMW больше 20ти лет. Дизельные, бензиновые, с турбиной или без, М-серия - мы знакомы со всеми из них, изучили их сильные и слабые стороны и готовы помочь вам с грамотным обслуживанием, качественным ремонтом и модифицированием.",
    custom: "Детали по индивидуальному проекту",
    entry: "Можно обращаться к нам с любыми идеями, а мы постараемся воплотить их в жизнь. Разработаем проект и изготовим деталь из любых материалов, на трехосных и пятиосных CNC-станках",
    list: "Примеры наших деталей:",
    det_head: "Впускные коллекторы для двигателя S63B44",
    det_head2: "Опоры дифференциала для автомобиля с двигателем S63B44",
    det_head3: "Опора для установки шестиступенчатой коробки передач на BMW E36",
    det_head4: "Поддон коробки передач DCT, BMW M6 F13",
    fix: "Наши услуги",
    hd1: "Обслуживание",
    txt1: "Производим полное обслуживание любых моделей BMW, для чего используем высококачественные моторные масла Ravenol и Liqui Moly",
    hd2: "Ремонт двигателя",
    txt2: "Ремонт и модифицирование двигателей любой сложности, под любые пожелания клиента, при помощи как оригинальных, так и усиленных компонентов.",
    hd3: "Сварочные работы.",
    txt3: "Сварочные работы по нержавеющей стали, алюминию. Замена деталей, поврежденных корозией",
    hd4: "Тюнинг и модифицирование",
    txt4: "Поднятие мощности - настройка и чиптюнинг, модификации системы наддува и топливной системы. Работа с подвеской и тормозами. Замена двигателя (swap)",
    hd5: "Диагностика",
    txt5: "Глубокая диагностика систем автомобиля, при необходимости возможен осмотр эндоскопом",
    hd6: "Тормоза",
    txt6: "Ремонт и улучшение тормозной системы",
    hd7: "Подвеска",
    txt7: "Ремонт и улучшение ходовой части автомобиля с использованием как оригинальных, так и усиленных компонентов от KW, Ohlins, Nitron и других производителей",
    proj: "Наши проекты",
    name: "Галерея проектов",
    car1: "E24 M62B44",
    txt_car1: "двигатель M62B44, стандартный ECU, выпускные коллекторы E53 4.8, выпуск из нержавеющей стали 2x63mm с наконечниками Remus, тормозная система от праворульной E34 540i c тормозными трубками IXT Performance, модифицированная винтовая подвеска от E39, колёса Hamann Motorsport R18",
    car2: "E24 M30B35 Turbo",
    txt_car2: "двигатель M30B35, VEMS ECU, поршни M102, прокладка головы на пирамидальных кольцах, впускной коллектор Alpina style, форсунки Siemens 700cc, топливный насос Bosch 044, twinscroll турбина на равнодлинных выпускных коллекторах, 90мм даунпайп IXT, выпуск 76мм IXT, коробка передач Getrag, сцепление XTR, маховик s38, задний редуктор 3.15 с блокировкой от Е46 М3, модифицированная винтовая подвеска, колёса Rial Daytona Race R18 cо сделанными на заказ полками, модифицированные поворотники и фонари (smoked)",
    car3: "E46 M3 (реплика M3 CSL)",
    txt_car3: "объём двигателя увеличен до 3.3, поршни JE, шатуны FCP, распредвалы 288/280, титановые ритейнеры и гоночные пружины клапанов, впуск Carbonius Race, выпускные коллекторы Supersprint Race, средняя секция выпуска 63мм IXT, титановая задняя банка глушителя весом 9кг, винтовая подвеска KW v2, сделанные на заказ развальные плиты и рычаги, распорки H&R, тормозная система с суппортами от 996 и BMW Performance, тормозные диски M3 CSL, тормозные трубки HEL, бампера CSL спереди и сзади, крышка багажника CSL, накладки на пороги CSL, руль Sparco, сиденье Sparco, ремни Sparco, сделанный на заказ полукаркас безопасности, сделанные на заказ детали внешности из карбона включая задний диффузор, дисплей EGT Control, лёгкий аккумулятор, колёса BBS CHR",
    car4: "E46 335i",
    txt_car4: "двигатель N54B30, опоры двигателя IXT, вся проводка и модули от E92, блоки управления стёклами и зеркалами IXT, двойной топливный насос низкого давления в баке, регулятор давления топлива AEM, тефлоновые топливопроводы, топливный насос высокого давления с отдельным модулем контроля, шкивы IXT, шпильки головы ARP, поршни и шатуны CP Carillo, портированная голова, распредвалы Cat, выпускные коллекторы IXT, два турбонагнетателя Gtx28, выпуск IXT, интеркулер Wagner, коробка передач от 330i (ручная 6 скоростей), шортшифтер Z4M, кардан и задний дифференциал от M3 E46, полуоси от 330d, cцепление Spec R Super Twin, cтабилизаторы М3 E46, тормозные диски M3 CSL, задние суппорта Porsche 996, передние суппорта BMW Performance, рулевые адаптеры IXT, винтовая подвеска HSD, развальные рычаги IXT, сайлентблоки M3 E46, зеркала E82, модифицированный передний бампер, пороги M-tech",
    car5: "F06 650xi N63",
    txt_car5: "гильзованный блок двигателя, поршни и шатуны CP Carillo, портированные головы, сбалансированный коленвал, модифицированные распредвалы, топливный насос высокого давления M5 f90, топливный насос низкого давления 700l/h, регулятор давления топлива DW, тефлоновые топливопроводы IXT, топливный фильтр Inline, впускные коллекторы IXT из алюминия, выпускные коллекторы IXT, даунпайпы IXT, выпуск BMW M6, два турбонагнетателя G25 660, интеркулеры CSF, cистема охлаждения гибрид деталей 650 и М6, редуктор 3.08, усиленная коробка передач, впуск этанола, дополнительные радиаторы и вентилятор для охлаждения топливной системы, модифицированная тормозная система от BMW M5 F90, винтовая подвеска KW v3, сделанные на заказ кованые диски R20, модифицированный капот",
    car6: "F13 M6 S63",
    txt_car6: "гильзованный блок двигателя, поршни JE, шатуны DP, шпильки головы ARP, портированные головы, два турбонагнетателя TTE 1100, интеркулеры CSF, впрыск этанола Aquamist, усиленное cцепление Deka Clutch ST4, поддон коробки Billet DCT pan, крепление дифференциала Billet diff mount, винтовая подвеска KW v3, кованые диски BC Forged R21, карбоновый диффузор 3DDesign",
    contact: "Контакты",
    IMG: main_img,
    IMG_sml: main_img_small,
    entrance_label: "Вход",
    google_maps_label: "Адрес в Google Maps",
    waze_label: "Адрес в Waze"
  },
  lv: {
    abt: "Par mums",
    text_abt: "Virs 20 gadu pieredze BMW remontā, apkopē un uzlabošanā. Dīzelis, benzīns, ar turbīnu vai bez, M sērija – mēs zinam, kas un kā ir jādara. Esam izpētījuši to stiprās un vājās puses un gatavi palīdzēt ar profesionālo apkopi, kvalitatīvu remontu un uzlabošanas iespējām.",
    custom: "Detaļas pēc individuala pāsūtījuma",
    entry: "Ja jums ir idejas - mēs varam tās īstenot. Izstrādāsim projektu un izgatavosim nepieciešamo detaļu no pareizā materiāla uz trīsasu un piecasu CNC iekārtām.",
    list: "Mūsu detaļu piemēri:",
    det_head: "S63B44 dzinēja ieplūdes kolektori",
    det_head2: "Diferenciāļa balsti automašīnai ar S63B44 dzinēju",
    det_head3: "BMW E36 balsts sešpakāpju ātrumkārbas uzstādīšanai",
    det_head4: "DCT ātrumkārbas karteris, BMW M6 F13",
    fix: "Mūsu pakalpojumi",
    hd1: "Apkope",
    txt1: "Veicam jebkura BMW modeļa pilnu apkopi, izmantojot augstākās kvaļitātes eļļas Ravenol un Liqui Moly",
    hd2: "Dzinēja remonts",
    txt2: "Dzinēju remonts un uzlabošana atbilstoši klienta prasībām, izmantojot gan BMW rūpnīcā ražotus, gan paaugstinātas izturības komponentus",
    hd3: "Metināšanas darbi",
    txt3: "Nerūsējošā tēraudā, alumīnija metināšana, kā arī korozijas bojāto detaļu nomaiņa",
    hd4: "Uzlabošanas iespējas",
    txt4: "Jaudas palielināšana - regulēšana un čiptūnings, turbīnu un degvielas sistēmas uzlabošana, darbi ar piekari un bremzēm. Dzinēja nomaiņa (swap)",
    hd5: "Diagnostika",
    txt5: "Dziļā automašīnas sistēmu diagnostika, nepieciešamības gadījumā iespējama apskate ar endoskopu",
    hd6: "Bremzes",
    txt6: "Bremžu sistēmas remonts un uzlabošana",
    hd7: "Piekare",
    txt7: "Remonts un uzlabošana, izmantojot gan BMW rūpnīcā ražotas daļas, gan no KW, Ohlins Nitron un citiem.",
    proj: "Mūsu projekti",
    name: "Projektu galerija",
    car1: "E24 M62B44",
    txt_car1: "dzinējs M62B44, standarta ECU, izplūdzes kolektori E53 4.8, nerusējoša tērauda izplūde 2x63mm ar Remus uzgaliem, bremžu sistēma no RHD E34 540i ar bremžu trubiņām IXT Performance, modifīcēta piekare ar koiloveriem E39, diski Hamann Motorsport R18",
    car2: "E24 M30B35 Turbo",
    txt_car2: "dzinējs M30B35, VEMS ECU, M102 virzuļi, galvas blīve uz piramidāliem gredzeniem, ieplūdes kolektors Alpina style, sprauslas Siemens 700cc, degvielas sūknis Bosch 044, twinscroll turbīna uz vienāda garuma izplūdes kolektora, 90мм daunpaips IXT, izplūde 76mm IXT, Getrag ātrumkārba, XTR sajūgs, spārrats S38, aizm.reduktors 3.15 ar bloķi no Е46 М3, modificēta piekare ar koiloveriem, rati Rial Daytona Race R18 ar uztaisītiem pēc pasūtījuma plauktiem, modificētie pagriezienrādītāji un aizm. lukturi (smoked)",
    car3: "E46 M3 (M3 CSL replika)",
    txt_car3: "dzinēja tilpums palielināts līdz 3.3, JE virzuļi, FCP klāņi, sadales vārpstas 288/280, titāna riteineri un sacīkšu vārstu atsperes, ieplūde Carbonius Race, izplūdes kolektori Supersprint Race, vidējā izplūdes sekcija 63mm IXT, izplūdes aizm.bundulis no titāna 9kg, koiloveri KW v2, uztaisītie pēc pasūtījuma savirzes plīti un sviras, statnes stienis H&R, bremžu sistēma ar suportiem no Porsche 996 un BMW Performance, bremžu diski M3 CSL, bremžu trubiņas HEL, CSL bamperi priekšā un aizmugurē, bagāžnieka vāks CSL, sliekšņu uzlikas CSL, Sparco stūre, sīksnas un sēdeklis, pec pasūtījuma taisītais drošības puskarkass, pēc pasūtījuma taisītas karbona detaļas ieskaitot aizmugures difuzoru, EGT Control displejs, viegls akumulators, diski BBS CHR",
    car4: "E46 335i",
    txt_car4: "dzinējs, dzinēja balsti IXT, E92 vadi un visi vadības moduļi, IXT stiklu un spoguļu vadības moduļi, dubultais zemspiediena degvielas sūknis bākā, degvielas spiediena regulators AEM, teflona degvielas vadi, augstspiediena degvielas sūknis ar atsevišķo kontroles moduli, IXT skriemeļi, ARP galvas tapas, virzuļi un klāņi CP Carillo, portēta galva, sadales vārpstas Cat, izplūdes kolektori IXT, divas turbīnas Gtx28, IXT izplūde, Wagner starpdzēsētājs, ātrumkārba no 330i (manuālā 6 ātrumi), Z4M šortšifteris, kardāns un aizm.diferenciālis no M3 E46, pusasis no 330d, sajūgs Spec R Super Twin, stabilizātori М3 E46, bremžu diski M3 CSL, aizm.suporti Porsche 996, priekšējie suporti BMW Performance, stūrēšanas adapteri IXT, HSD koiloveri, savirzes sviras IXT, M3 E46 sailentbloķi, E82 spoguļi, modificētais priekšējais bamperis, sliekšņi M-tech",
    car5: "F06 650xi N63",
    txt_car5: "dzinēja bloks ar piedurknēm, virzuļi un klāņi CP Carillo, portētas galvas, balancēta kloķvārpsta, modificētas sadales vārpstas, augstspiediena degvielas sūknis M5 f90, zemspiediena degvielas sūknis 700l/h, DW degvielas spiediena regulātors, teflona degvielas vadi IXT, degvielas filtrs Inline, ieplūdes kolektori IXT no alumīnija, izplūdes kolektori IXT, daunpaipi IXT, izplūde BMW M6, divas turbīnas G25 660, CSF starpdzēsētāji, dzēsēšanas sistēma hibrīds no 650 un М6 detaļām, reduktors 3.08, modificēta ātrumkārba, etanola ieplūde, papildus radiatori un ventilātors degvielas sistēmas dzēsēšanai, modificēta bremžu sistēma no BMW M5 F90, koiloveri KW v3, pēc pasūtījuma taisītie kaltie diski R20, modificēta haube",
    car6: "F13 M6 S63",
    txt_car6: "dzinēja bloks ar piedurknēm, virzuļi JE, klāņi DP, ARP galvas tapas, portētas galvas, divas turbīnas TTE 1100, CSF starpdzēsētāji, etamola ieplūde Aquamist, pastiprināts sajūgs Deka Clutch ST4, kārbas paliktnis Billet DCT pan, diferenciāla balsts Billet diff mount, koiloveri KW v3, kaltie diski BC Forged R21, karbona difuzors 3DDesign",
    contact: "Kontakti",
    IMG: main_img_lv,
    IMG_sml: main_img_small_lv,
    entrance_label: "Ieeja",
    google_maps_label: "Google Maps adrese",
    waze_label: "Waze adrese"
  },
  en: {
    abt: "About us",
    text_abt: "Over 20 years of BMW repairing, servicing, and improving experience. Diesels, petrols, turbo or N/A, M-series – we know all of them, have studied their strengths and weak points. We are ready to assist you with professional maintenance, high-quality repairs, and modifications.",
    custom: "Custom parts",
    entry: "You can approach us with any ideas, and we will do our best to bring them to life. Will design and manufacture custom part from any material using three-axis and five-axis CNC machines for you.",
    list: "Examples of our parts:",
    det_head: "Intake manifolds for S63B44 engine",
    det_head2: "Differential mounts to be used on car with S63B44 engine",
    det_head3: "BMW E36 mount for installing six speed manual gearbox",
    det_head4: "DCT gearbox sump, BMW M6 F13",
    fix: "Our services",
    hd1: "Maintenance",
    txt1: "We provide full servicing for any BMW model, using high-quality oils from Ravenol and Liqui Moly",
    hd2: "Engine repair",
    txt2: "Engine repair and modification of any complexity, according to the client's wishes, using BMW factory or best aftermarket components.",
    hd3: "Welding works",
    txt3: "Welding works on stainless steel, aluminum, as well as replacement of parts damaged by corrosion",
    hd4: "Tuning and modification",
    txt4: "Power increase - tuning and chiptuning, turbo system and fuel system modification, suspension and brake work. Engine swaps and more",
    hd5: "Diagnostics",
    txt5: "In-depth car system diagnostics, with the possibility of an endoscope inspection if necessary",
    hd6: "Brakes",
    txt6: "Brake system repairs and modification",
    hd7: "Suspension",
    txt7: "Repair and improvement of vehicle's suspension using BMW factory produced parts and best aftermarket components like KW, Ohlins, and Nitron.",
    proj: "Our projects",
    name: "Project gallery",
    car1: "E24 M62B44",
    txt_car1: "M62B44 engine, stock ECU, E53 4.8 headers, stainless steel exhaust 2x63mm with Remus tailpipes, e34 540i RHD brake sistem with custom linings, E39 coilovers customized to chassis, Hamann Motorsport R18 wheels",
    car2: "E24 M30B35 Turbo",
    txt_car2: "M30B35 engine, VEMS ECU, M102 pistons, head gasket with pyramid rings, Alpina style intake manifold, Siemens 700cc injectors, fuel pump Bosch 044, twinscroll turbocharger on equal length exhaust manifold, 90mm downpipe IXT, exhaust 76mm IXT, gearbox Getrag, XTR clutch, S38 flywheel, 3.15 diff with Е46 М3 LSD, modified coilovers, wheels Rial Daytona Race R18 with custom lips, smoked tail lights and turn indicators",
    car3: "E46 M3 (M3 CSL tribute)",
    txt_car3: "3.3L engine, pistons JE, FCP rods, cams 288/280, titanium retainers and racing valve springs, intake Carbonius Race, Supersprint Race headers, middle exhaust section 63мм IXT, titanium muffler 9kg, KW v2 coilovers, custom camber plates and camber arms, H&R strut bars, Porsche 996 and BMW Performance brake callipers, brake rotors M3 CSL, HEL brake linings, CSL front and rear bumpers, trunk, scuff plates, Sparco steering wheel, seat, belts, custom halfcage, custom carbon trim, EGT Control display, lightweight accu, BBS CHR wheels",
    car4: "E46 335i",
    txt_car4: "N54B30 engine, IXT engine mounts, all body wiring and modules from e92, custom controller for windows and mirrors IXT, Twin lpfp, fuel pressure regulator AEM, custom teflon piping, second hpfp with standalone controller, IXT pulleys, ARP head studs, pistons and rods CP Carillo, ported head, Cat cams, IXT headers, two turbochargers Gtx28, IXT exhaust, Wagner intercooler, 330i gearbox (manual 6 speed), Z4M shortshifter, M3 E46 shaft, 330d halfshafts, Spec R Super Twin clutch, stabilizers М3 E46, M3 CSL E46 rotors, rear calipers Porsche 996, front calipers BMW Performance, steering adaptors IXT, HSD coilovers, IXT cnc adaptors, M3 E46 bushings, E82 mirrors, modified front bumper, M-tech sideskirts",
    car5: "F06 650xi N63",
    txt_car5: "sleeved block, pistons and rods CP Carillo, ported heads, balanced crank, modified cams, hpfp M5 f90, lpfp 700l/h, fuel pressure regulator DW, teflon fuel lines IXT, fuel filter Inline, aluminium intake IXT, headers IXT, downpipes IXT, exhaust BMW M6, two turbochargers G25 660, intercoolers CSF, cooling sistem hybrid from 650 and m6 parts, diff 3.08, modified gearbox, ethanol injection, custom radiators and cooling fan for fuel sistem, modified brakes from BMW M5 F90, coilovers KW v3, custom forged R20 wheels, modified hood",
    car6: "F13 M6 S63",
    txt_car6: "sleeved block, JE pistons, DP rods, ARP head studs, ported heads, two turbochargers TTE 1100, CSF intercoolers, ethanol injection Aquamist, custom clutch Deka Clutch ST4, gearbox pan Billet DCT, Billet diff mount, coilovers KW v3, forged wheels BC Forged R21, CF diffusor 3DDesign",
    contact: "Contacts",
    IMG: main_img_en,
    IMG_sml: main_img_small_en,
    entrance_label: "Entrance",
    google_maps_label: "Google Maps Address",
    waze_label: "Waze Address"
  }
};

const Home = () => {
  const { language, changeLanguage } = useContext(LanguageContext);
  const [imageSrc, setImageSrc] = useState('');
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1500);
  const correctPassword = "BMWautoservice";

  useEffect(() => {
    const updateImageSrc = () => {
      const width = window.innerWidth;
      if (width <= 426) {
        setImageSrc(Translations_local[language].IMG_sml);
      } else {
        setImageSrc(Translations_local[language].IMG);
      }
    };
    updateImageSrc();
    window.addEventListener('resize', updateImageSrc);
    return () => window.removeEventListener('resize', updateImageSrc);
  }, [language]);

  useEffect(() => {
    const handleResize = () => setIsMobileView(window.innerWidth < 1500);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password === correctPassword) {
      setIsAuthenticated(true);
    } else {
      alert("Incorrect password. Please try again.");
      setPassword('');
    }
  };

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    changeLanguage(newLanguage);
  };

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  function show_custom() {
    if (document.getElementById('about us').style.display !== 'none') document.getElementById('about us').style.display = 'none';
    if (document.getElementById('services').style.display !== 'none') document.getElementById('services').style.display = 'none';
    if (document.getElementById('projects').style.display !== 'none') document.getElementById('projects').style.display = 'none';
    if (document.getElementById('contacts').style.display !== 'none') document.getElementById('contacts').style.display = 'none';
    document.getElementById('custom parts').style.display = 'block';
  }

  function show_about() {
    if (document.getElementById('custom parts').style.display !== 'none') document.getElementById('custom parts').style.display = 'none';
    if (document.getElementById('services').style.display !== 'none') document.getElementById('services').style.display = 'none';
    if (document.getElementById('projects').style.display !== 'none') document.getElementById('projects').style.display = 'none';
    if (document.getElementById('contacts').style.display !== 'none') document.getElementById('contacts').style.display = 'none';
    document.getElementById('about us').style.display = 'block';
  }

  function show_services() {
    if (document.getElementById('custom parts').style.display !== 'none') document.getElementById('custom parts').style.display = 'none';
    if (document.getElementById('about us').style.display !== 'none') document.getElementById('about us').style.display = 'none';
    if (document.getElementById('projects').style.display !== 'none') document.getElementById('projects').style.display = 'none';
    if (document.getElementById('contacts').style.display !== 'none') document.getElementById('contacts').style.display = 'none';
    document.getElementById('services').style.display = 'block';
  }

  function show_projects() {
    if (document.getElementById('custom parts').style.display !== 'none') document.getElementById('custom parts').style.display = 'none';
    if (document.getElementById('about us').style.display !== 'none') document.getElementById('about us').style.display = 'none';
    if (document.getElementById('services').style.display !== 'none') document.getElementById('services').style.display = 'none';
    if (document.getElementById('contacts').style.display !== 'none') document.getElementById('contacts').style.display = 'none';
    document.getElementById('projects').style.display = 'block';
  }

  function show_contacts() {
    if (document.getElementById('custom parts').style.display !== 'none') document.getElementById('custom parts').style.display = 'none';
    if (document.getElementById('about us').style.display !== 'none') document.getElementById('about us').style.display = 'none';
    if (document.getElementById('services').style.display !== 'none') document.getElementById('services').style.display = 'none';
    if (document.getElementById('projects').style.display !== 'none') document.getElementById('projects').style.display = 'none';
    document.getElementById('contacts').style.display = 'block';
  }

  if (!isAuthenticated) {
    return (
      <div className="password-screen">
        <h2>Website is in testing</h2>
        <p>Please enter a password before you can view this page</p>
        <form onSubmit={handlePasswordSubmit}>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
            className="password-input"
          />
          <button type="submit" className="submit-button">Submit</button>
        </form>
      </div>
    );
  }

  return (
    <div className="App">
      <div className='Main-pic'>
        <img src={imageSrc} id="main-img" className='main-img'/>
      </div>
      <div className="content-selector">
        {isMobileView ? (
          <>
            <div className="dropdown-toggle" onClick={toggleDropdown}>
              {isDropdownOpen ? <FaTimes /> : <FaBars />}
            </div>
            <div className="dropdown-menu" style={{ display: isDropdownOpen ? 'flex' : 'none' }}>
              <div className="Links-mobile">
                <button className="Link" onClick={show_about}>{Translations_local[language].abt}</button>
                <button className="Link" onClick={show_custom}>{Translations_local[language].custom}</button>
                <button className='Link' onClick={show_services}>{Translations_local[language].fix}</button>
                <button className='Link' onClick={show_projects}>{Translations_local[language].proj}</button>
                <button className='Link' onClick={show_contacts}>{Translations_local[language].contact}</button>
              </div>
            </div>
          </>
        ) : (
          <div className="Links-desk">
            <button className="Link" onClick={show_about}>{Translations_local[language].abt}</button>
            <button className="Link" onClick={show_custom}>{Translations_local[language].custom}</button>
            <button className='Link' onClick={show_services}>{Translations_local[language].fix}</button>
            <button className='Link' onClick={show_projects}>{Translations_local[language].proj}</button>
            <button className='Link' onClick={show_contacts}>{Translations_local[language].contact}</button>
          </div>
        )}
      </div>

      <div className='about_us' id='about us'>
        <h2>{Translations_local[language].abt}</h2>
        <p className='text_abt'>{Translations_local[language].text_abt}</p>
      </div>

      <div className='custom_parts' id='custom parts'>
  <h1>{Translations_local[language].custom}</h1>
  <p>{Translations_local[language].entry}</p>
  <br/>
  <h2>{Translations_local[language].list}</h2>
  <div className='info_row'>
    <img src={custom1} className='info_image_mobile' />
    <img src={custom1} className='info_image' />
    <div className='breaker'></div>
    <div className='textbox'>
      <h3>{Translations_local[language].det_head}</h3>
    </div>
  </div>
  <br/>
  <div className='info_row'>
    <img src={custom2} className='info_image_mobile' />
    <div className='textbox'>
      <h3>{Translations_local[language].det_head2}</h3>
    </div>
    <div className='breaker'></div>
    <img src={custom2} className='info_image_right' />
  </div>
  <br/>
  <div className='info_row'>
    <img src={custom3} className='info_image_mobile' />
    <img src={custom3} className='info_image' />
    <div className='breaker'></div>
    <div className='textbox'>
      <h3>{Translations_local[language].det_head3}</h3>
    </div>
  </div>
  <br/>
  <div className='info_row'>
    <img src={custom4} className='info_image_mobile' />
    <div className='textbox'>
      <h3>{Translations_local[language].det_head4}</h3>
    </div>
    <div className='breaker'></div>
    <img src={custom4} className='info_image_right' />
  </div>
</div>

      <div className="services" id="services">
        <h1>{Translations_local[language].fix}</h1>
        <br /><br />
        <div className="info_row">
          <img src={remont1} className="info_image_mobile" />
          <div className="textbox">
            <h3>{Translations_local[language].hd1}</h3>
            <p>{Translations_local[language].txt1}</p>
          </div>
          <div className="breaker"></div>
          <img src={remont1} className="info_image" />
        </div>
        <br /><br />
        <div className="info_row">
          <img src={remont2} className="info_image_mobile" />
          <img src={remont2} className="info_image" />
          <div className="breaker"></div>
          <div className="textbox">
            <h3>{Translations_local[language].hd2}</h3>
            <p>{Translations_local[language].txt2}</p>
          </div>
        </div>
        <br /><br />
        <div className="info_row">
          <img src={remont3} className="info_image_mobile" />
          <div className="textbox">
            <h3>{Translations_local[language].hd3}</h3>
            <p>{Translations_local[language].txt3}</p>
          </div>
          <div className="breaker"></div>
          <img src={remont3} className="info_image" />
        </div>
        <br /><br />
        <div className="info_row">
          <img src={remont4} className="info_image_mobile" />
          <img src={remont4} className="info_image" />
          <div className="breaker"></div>
          <div className="textbox">
            <h3>{Translations_local[language].hd4}</h3>
            <p>{Translations_local[language].txt4}</p>
          </div>
        </div>
        <br /><br />
        <div className="info_row">
          <img src={remont5} className="info_image_mobile" />
          <div className="textbox">
            <h3>{Translations_local[language].hd5}</h3>
            <p>{Translations_local[language].txt5}</p>
          </div>
          <div className="breaker"></div>
          <img src={remont5} className="info_image" />
        </div>
        <br /><br />
        <div className="info_row">
          <img src={remont6} className="info_image_mobile" />
          <img src={remont6} className="info_image" />
          <div className="breaker"></div>
          <div className="textbox">
            <h3>{Translations_local[language].hd6}</h3>
            <p>{Translations_local[language].txt6}</p>
          </div>
        </div>
        <br /><br />
        <div className="info_row">
          <img src={remont7} className="info_image_mobile" />
          <div className="textbox">
            <h3>{Translations_local[language].hd7}</h3>
            <p>{Translations_local[language].txt7}</p>
          </div>
          <div className="breaker"></div>
          <img src={remont7} className="info_image" />
        </div>
      </div>

      <div className='projects' id='projects'>
        <h1>{Translations_local[language].name}</h1>
        <br/>
        <div className='info_row'>
          <img src={project2} className='info_image-proj' />
          <div className='textbox-proj'>
            <h3>{Translations_local[language].car1}</h3>
            <p>{Translations_local[language].txt_car1}</p>
          </div>
        </div>
        <br/>
        <div className='info_row'>
          <img src={project1} className='info_image-proj' />
          <div className='textbox-proj'>
            <h3>{Translations_local[language].car2}</h3>
            <p>{Translations_local[language].txt_car2}</p>
          </div>
        </div>
        <br/>
        <div className='info_row'>
          <img src={project4} className='info_image-proj' />
          <div className='textbox-proj'>
            <h3>{Translations_local[language].car3}</h3>
            <p>{Translations_local[language].txt_car3}</p>
          </div>
        </div>
        <br/>
        <div className='info_row'>
          <img src={project3} className='info_image-proj' />
          <div className='textbox-proj'>
            <h3>{Translations_local[language].car4}</h3>
            <p>{Translations_local[language].txt_car4}</p>
          </div>
        </div>
        <br/>
        <div className='info_row'>
          <img src={project6} className='info_image-proj' />
          <div className='textbox-proj'>
            <h3>{Translations_local[language].car5}</h3>
            <p>{Translations_local[language].txt_car5}</p>
          </div>
        </div>
        <br/>
        <div className='info_row'>
          <img src={project5} className='info_image-proj' />
          <div className='textbox-proj'>
            <h3>{Translations_local[language].car6}</h3>
            <p>{Translations_local[language].txt_car6}</p>
          </div>
        </div>
      </div>

      <div className='contacts' id='contacts'>
        <h1>{Translations_local[language].contact}</h1>
        <div style={{ textAlign: 'center' }}>
          <div className='line-cont'>
            <ion-icon name="newspaper"></ion-icon>
            <p>Rīga, Marsa gatve 6 - 54, LV-1082</p>
          </div>
          <div className='line-cont'>
            <ion-icon name="checkmark-circle"></ion-icon>
            <p>IXT parts, SIA, Reģ nr. 40103940141</p>
          </div>
          <div className='contact-images'>
            <div className='contact-item'>
              <a href="https://maps.app.goo.gl/9NUxe2ts65g1xD89A" target="_blank" rel="noopener noreferrer">
                <img src={contact1} className='info_image' alt="Google Maps Address" />
              </a>
              <p>{Translations_local[language].google_maps_label}</p>
            </div>
            <div className='contact-item'>
              <img src={contact2} className='info_image' alt="Entrance" />
              <p>{Translations_local[language].entrance_label}</p>
            </div>
            <div className='contact-item'>
              <a href="https://waze.com/ul/hud1hjg5fp" target="_blank" rel="noopener noreferrer">
                <img src={waze} className='info_image' alt="Waze Address" />
              </a>
              <p>{Translations_local[language].waze_label}</p>
            </div>
          </div>
        </div>
      </div>
      <br/><br/><br/><br/><br/>
      <footer className='footer'>
        <div className='line'>
          <ion-icon name="newspaper"></ion-icon>
          <p>Rīga, Marsa gatve 6 - 54, LV-1082</p>
        </div>
        <div className='line'>
          <ion-icon name="checkmark-circle"></ion-icon>
          <p>IXT parts, SIA, Reģ nr. 40103940141</p>
        </div>
      </footer>

      <Helmet>
        <script type="module" src="https://unpkg.com/ionicons@7.1.0/dist/ionicons/ionicons.esm.js"></script>
        <script nomodule src="https://unpkg.com/ionicons@7.1.0/dist/ionicons/ionicons.js"></script>
      </Helmet>
    </div>
  );
};

export default Home;