import React, { useState, useEffect, useContext } from 'react';
import { Outlet, Link } from "react-router-dom";
import '../stylesheets/App.css';
import facebook from '../images/facebook.png';
import {Helmet} from "react-helmet";
import { LanguageContext } from "../Contexts/Languagecontext.js";
import { FaBars, FaTimes } from 'react-icons/fa';

const Translations = {
    ru: {
        abt: "О нас",
        custom: "Кастомные детали",
        fix: "Наши услуги",
        proj: "Наши проекты",
        contact: "Контакты"
    },
    lv: {
        abt: "Par mums",
        custom: "detaļas pēc individuala pāsutijuma",
        fix: "Mūsu pakalpojumi",
        proj: "Mūsu projekti",
        contact: "Kontakti"
    },
    en: {
        abt: "About us",
        custom: "Custom parts",
        fix: "Our services",
        proj: "Our projects",
        contact: "Contacts"
    }
};

const Base = () => {
    const { language, changeLanguage } = useContext(LanguageContext);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1500);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth < 1500);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleLanguageChange = (event) => {
        const newLanguage = event.target.value;
        changeLanguage(newLanguage);
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    return (
        <>
            <header className="App-header">
                {isMobileView ? (
                    <>
                        <div className="dropdown-toggle" onClick={toggleDropdown}>
                            {isDropdownOpen ? <FaTimes /> : <FaBars />}
                        </div>
                        <div className="dropdown-menu" style={{ display: isDropdownOpen ? 'flex' : 'none' }}>
                            <div className="Links-mobile">
                            <div className = 'line-header'>
                                <ion-icon name="call"></ion-icon>
                                <p>+371 26 097 304</p>
                            </div>
                            <div className = 'line-header'>
                                <ion-icon name="mail"></ion-icon>
                                <p>customcars@inbox.lv</p>
                            </div>
                            <div className = 'line-header'>
                                <ion-icon name="location"></ion-icon>
                                <p>Rīga, VEF, Ūnijas 6</p>
                            </div>
                                <a href='https://www.facebook.com/ixt.lv/'>
                                    <img src={facebook} className='facebook' alt="Facebook" />
                                </a>
                                <select name='lang_id' id='lang' className='select' value={language} onChange={handleLanguageChange}>
                                    <option value='ru'>Русский</option>
                                    <option value='lv'>Latviešu</option>
                                    <option value='en'>English</option>
                                </select>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="Links-desk">
                            <a href='https://www.facebook.com/ixt.lv/' className='facebook'>
                                <img src={facebook} className='facebook' alt="Facebook" />
                            </a>
                            <div className = 'line-header'>
                                <ion-icon name="call"></ion-icon>
                                <p>+371 26 097 304</p>
                            </div>
                            <div className = 'line-header'>
                                <ion-icon name="mail"></ion-icon>
                                <p>customcars@inbox.lv</p>
                            </div>
                            <div className = 'line-header'>
                                <ion-icon name="location"></ion-icon>
                                <p>Rīga, VEF, Ūnijas 6</p>
                            </div>
                        <select name='lang_id' id='lang' className='select' value={language} onChange={handleLanguageChange}>
                            <option value='ru'>Русский</option>
                            <option value='lv'>Latviešu</option>
                            <option value='en'>English</option>
                        </select>
                    </div>
                )}
            <Helmet>
                <script type="module" src="https://unpkg.com/ionicons@7.1.0/dist/ionicons/ionicons.esm.js"></script>
                <script nomodule src="https://unpkg.com/ionicons@7.1.0/dist/ionicons/ionicons.js"></script>
            </Helmet>
            </header>
            <Outlet />
        </>
    );
};

export default Base;