import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './Home.js'
import Base from './Base.js'
import facebook from '../images/facebook.png'
import { LanguageProvider } from "../Contexts/Languagecontext.js";

export default function App() {

    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then(registrations => {
          registrations.forEach(registration => registration.unregister());
        });
      }
      
    return(
        <LanguageProvider>
        <BrowserRouter>
            <Routes>
                <Route path = '/' element = {<Base />}>
                    <Route index element = {<Home />}></Route>
                </Route>
            </Routes>
        </BrowserRouter>
        </LanguageProvider>
    );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
